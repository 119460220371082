
if(process.env.NODE_ENV=="development") {
    function changeBackground(color) {
        document.body.style.background = color;
     }
     
     window.addEventListener("load",function() { changeBackground('red') });    
}

import aiPill from './ai-pill.vue'
Vue.component('ai-pill',aiPill)

import customAlertComponent from './custom-alert-component.vue'
Vue.component('custom-alert-component',customAlertComponent)

import customCard from './custom-card.vue'
Vue.component('custom-card',customCard)

import customClientPlaceholder from './custom-client-placeholder.vue'
Vue.component('custom-client-placeholder',customClientPlaceholder)

import customInputField from './custom-input-field.vue'
Vue.component('custom-input-field',customInputField)

import customLoader from './custom-loader.vue'
Vue.component('custom-loader',customLoader)

import customModalComponent from './custom-modal-component.vue'
Vue.component('custom-modal-component',customModalComponent)

import customTableComponent from './custom-table-component.vue'
Vue.component('custom-table-component',customTableComponent)

import statusBar from './status-bar.vue'
Vue.component('status-bar',statusBar)

import customBreadcrumb from './custom-breadcrumb.vue'
Vue.component('custom-breadcrumb',customBreadcrumb)

import customTooltip from './custom-tooltip.vue'
Vue.component('custom-tooltip',customTooltip)

import Inputmask from "inputmask"
Inputmask.extendDefaults({
    autoUnmask: true
})
Vue.directive('mask', {
    bind: function(el, binding, vnode){
        if(!binding.value){
            return;
        }
        if(typeof binding.value !== 'object'){
            binding.value = {mask: binding.value}
        }
        Inputmask(binding.value).mask(el)
    },
    unbind: function(el){
        if (el.inputmask){
            el.inputmask.remove()
        }
    }
})

import JsZip from 'jszip';
import FileSaver from 'file-saver';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
window.JsZip = JsZip
window.FileSaver = FileSaver;
window.PDFDocument = PDFDocument;

import customPromisedAlertComponent from './custom-promised-alert-component.vue'
Vue.component('custom-promised-alert-component',customPromisedAlertComponent)