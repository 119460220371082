<template>
    <div class="card">
        <custom-loader :show="loader"></custom-loader>
        <a class="custom-card-header" v-on:click="$emit('header_click')">
            <slot name="header"></slot> 
        </a>
        <transition name="slide">
            <div class="custom-card-body" v-show="active">
                <slot name="body"></slot>
            </div>
        </transition>
    </div>
</template>
<script>
module.exports = {
    name:'customCard',
    props:{
        active:{
            type:Boolean,
            default:true
        },
        loader:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style scoped>
.card .loader-container{
    position: absolute;
}

.slide-enter-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 500px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>