<template>
    <custom-alert-component v-bind="mergedModalSettings" v-if="modalShown" @outside_click="close">
        <template v-slot:alert-text>
            <slot v-bind:close="close" v-bind:select="select" name="alert-text"></slot>
        </template>
        <template v-slot:alert-buttons>
            <slot v-bind:close="close" v-bind:select="select" name="alert-buttons"></slot>
        </template>
    </custom-alert-component>
</template>
<script>
import customAlertComponent from './custom-alert-component.vue'

export default {
    name:'promisedCustomAlertComponent',
    props:{
        modalSettings:{
            type: Object,
            default: ()=>({})
        },
        rejectBehavior:{
            default: undefined,
            validator: (value)=>{
                return typeof value !== 'string' || value === 'reject'
            }
        }
    },
    components:{
        customAlertComponent
    },
    computed:{
        mergedModalSettings(){
            return {...this.modalSettings, ...this.componentModalSettings}
        }
    },
    data(){
        return {
            componentModalSettings: {
                active:         true
            },
            modalShown:             false,
            promiseResolve:         undefined,
            promiseReject:          undefined
        }
    },
    methods:{
        //exposed methods
        get(){
            if(this.modalShown){
                this.close()
            }
            this.modalShown = true
            return new Promise((resolve, reject)=>{
                this.promiseResolve = resolve
                this.promiseReject = reject
            })
        },
        close(){
            this._reject()
            this._cleanup()
        },
        select(row){
            this.promiseResolve(row)
            this._cleanup()
        },
        //internal methods
        _reject(){
            if(this.rejectBehavior === 'reject'){
                this.promiseReject()
            }
            else{
                this.promiseResolve(this.rejectBehavior)
            }
        },
        _cleanup(){
            this.promiseResolve = undefined
            this.promiseReject = undefined
            this.modalShown = false
        }
    }
}
</script>
