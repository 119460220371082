<template>
    <div>
        <div class="row">
            <div class="col-md-6" v-bind:class="{'col-md-10':!cal_range && !records_per_page,'col-md-7':!records_per_page,'col-md-9':!cal_range}">
                <div class="form-inline md-form form-sm mt-0">
                    <i class="fas fa-search "></i>
                    <input ref="searchInput" v-model="actual_search_phrase" v-on:keyup="checkEnter" type="text" class="form-control form-control-sm ml-3 w-75" :placeholder="placeholder.search" autofocus>
                </div>        
            </div>
            <div v-if="cal_range" class="col-md-2">
                <div class="form-inline md-form form-sm mt-0">
                    <i class="fas fa-calendar-week"></i>
                    <input type="text" class="form-control form-control-sm ml-3 w-75 flatpickr" :placeholder="placeholder.date_range">
                </div> 
            </div>
            <div v-if="records_per_page" class="col-md-2">
                <div class="form-inline md-form form-sm mt-0">
                    <select v-model="selectedPagination" class="browser-default custom-select" id="exampleFormControlSelect1">
                        <option v-for="item in per_page_list">{{ item }}</option>
                    </select>
                </div> 
            </div>
        </div>
        <status-bar v-if="altered_statuses.length>1 && status_buttons==true" :statuses="altered_statuses" :selected="getStatusByNameOrId(actual_status).id" @select="(selected)=>{status(selected.name)}"></status-bar>
        <div class="row">
            <div class="table-responsive">
                <custom-loader :show="loader_active" loader_type="table"></custom-loader> 
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th v-bind:class="{'sortup':(column_state[item.name]=='asc' || (typeof item.sortFieldName!=='undefined'&&column_state[item.sortFieldName]=='asc')),'sortdown':(column_state[item.name]=='desc' || (typeof item.sortFieldName!=='undefined'&&column_state[item.sortFieldName]=='desc')),'no-sort':(typeof item.sortable !== 'undefined' && !item.sortable)}" v-on:click="sort(typeof item.sortFieldName!=='undefined'?item.sortFieldName:item.name, item.sortable)"  v-for="item in columns" v-if="!(item.hasOwnProperty('show')&&item.show==false)"> 
                                <a href="javascript:void(0)">{{ item.displayName }}</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>              
                        <tr v-on:click="$emit('rowclick',full_rows_data[rows_index])" v-for="rows,rows_index in rows_data" v-bind:class="rowClassGenerator(rows)">
                            <td v-for="val,index in rows" :key="index" v-if="!(not_show_cols_index.indexOf(index)>=0)">
                                <template v-if="index==statusCol_name">
                                    <span class="badge" v-bind:style="{'background-color':getStatusByNameOrId(val)['color']}">{{ getStatusByNameOrId(val)["displayName"]}}</span>
                                </template>
                                <template v-else-if="typeof columns[getColByName(index)].renderFun === 'function'">
                                    <div v-html="columns[getColByName(index)].renderFun(rows)"></div>
                                </template>
                                <template v-else-if="typeof columns[getColByName(index)].button !== 'undefined'">
                                    <a v-for="button,i in columns[getColByName(index)].button" href="javascript:void(0)" v-on:click.stop="button.fun(rows)" :class="'btn btn-flat btn-large ' + button.class" v-html="button.html"></a>
                                </template>
                                <template v-else-if="typeof columns[getColByName(index)].checkbox !== 'undefined'">
                                    <label v-on:click.stop class="control control-checkbox" :for="rows.id">
                                        <input type="checkbox" :id="rows.id" v-model="selected_rows" :value="{id:rows.id,valid:rows.is_valid,sscc:rows.sscc}">
                                        <div class="control_indicator"></div>
                                    </label>
                                </template>
                                <template v-else-if="typeof columns[getColByName(index)].aiPills !== 'undefined' && columns[getColByName(index)].aiPills === true">
                                    <template v-for="(ai, i) in rows.ais" >
                                        <ai-pill :ai="ai"></ai-pill>{{ i < rows.ais.length -1 ? '&#32;': '' }}
                                    </template>
                                </template>
                                <template v-else-if="typeof columns[getColByName(index)].select !== 'undefined'">
                                    <select v-on:click.stop class="browser-default custom-select" @change="columns[getColByName(index)].select($event,rows)">
                                        <option v-if="val === null" value="" hidden selected></option>
                                        <option v-for="option in select_options" :value="option.id" :selected="option.id == val">{{option.name}}</option>
                                    </select>
                                </template>
                                <template v-else>
                                    {{ val }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" v-if="pagination_list.length">
            <nav style="margin:auto">
                <ul class="pagination">
                    <li class="paginate_button page-item previous"><a class="page-link" v-bind:class="{'disabled':!prev_page_active}" v-on:click="paginate(actual_page-1)" href="#"><</a></li>
                    <li v-for="(n,index) in pagination_list"  v-on:click="paginate(n)" class="paginate_button page-item" v-bind:class="{'active':actual_page==n,'disabled':n==='...'}"><a class="page-link" href="#">{{ n }}</a></li>
                    <li class="paginate_button page-item next"><a v-bind:class="{'disabled':!next_page_active}" class="page-link" v-on:click="paginate(actual_page+1)" href="#">></a></li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
module.exports = {
    name:'customTableComponent',
    props:{
        columns:{ //format as array of objects [{name:database_name,displayName:displayName} {} {} {}]
            type:Array,
            default:()=>[]
        },
        statuses:{
            type:Array,
            default:()=>[]
        },
        url:{
            type:String,
            default:''
        },
        per_page_list:{
            type:Array,
            default:()=>[10,30,50,100]
        },
        cal_range:{
            type:Boolean,
            default:true
        },
        records_per_page:{
            type:Boolean,
            default:true
        },
        url_params:{
            type:Boolean,
            default:true
        },
        status_buttons:{
            type:Boolean,
            default:true
        },
        pagination_size:{
            type:Number,
            default:10
        },
        select_options:{
            type:Array,
            default:()=>[]
        },
        row_class:{
            type:Object,
            default:()=>{}
        }
    },
    mounted() {
        this.$refs.searchInput.focus()
        if(this.url_params) {
            this.getParamsFromUrl()
        }
        this.getRecords(true)
    },
    computed:{
        altered_statuses:function(){
            var statuses_translated = this.statuses.map((elem)=>{
                elem.displayName = gettext(this.placeholder[elem.name])
                return elem
            })
            return [{
                "name":'all',
                "displayName":this.placeholder.all,
                "status_class":"default",
                "color":"#e8e8e8",
                "num":0
            }].concat(statuses_translated);
        },
        statusCol_name:function() {
            for(i=0;i<this.columns.length;i++) {
                if(this.columns[i].hasOwnProperty('statusCol')) {
                    if(this.columns[i]["statusCol"]==true) {
                        return this.columns[i].name
                    }
                }
            }
            return -1
        },
        not_show_cols_index:function() {
            index_array = []
            for(i=0;i<this.columns.length;i++) {
                if(this.columns[i].hasOwnProperty('show')) {
                    if(this.columns[i]["show"]==false) {
                        index_array.push(this.columns[i].name)
                    }
                }
            }
            return index_array;
        },
        pagination_list:function(){
            var current = this.actual_page,
                last = this.pages_cnt,
                delta = 2,
                left = current - delta,
                right = current + delta + 1,
                range = [],
                rangeWithDots = [],
                l;

            for (let i = 1; i <= last; i++) {
                if (i == 1 || i == last || i >= left && i < right) {
                    range.push(i);
                }
            }

            for (let i of range) {
                if (l) {
                    if (i - l === 2) {
                        rangeWithDots.push(l + 1);
                    } else if (i - l !== 1) {
                        rangeWithDots.push('...');
                    }
                }
                rangeWithDots.push(i);
                l = i;
            }

            return rangeWithDots;
        },
        pages_cnt:function(){
            var ret = Math.ceil(this.actual_sum_records/this.actual_records_per_page);
            if(ret==0) ret=1;
            return ret;
        },
        next_page_active:function(){
            return this.actual_page!=this.pages_cnt;
        },
        prev_page_active:function(){
            return this.actual_page!=1;
        }
    },

    methods:{
        giveFocus(){
            this.$refs.searchInput.focus()
        },
        async checkEnter(e){
            if(typeof e.code === 'string' && (e.code == 'Enter' || e.code == 'NumpadEnter')){
                await this.getRecords();
                if(this.rows_data.length == 1){
                    this.$emit('rowclick',this.full_rows_data[0]);
                }
            }
        },
        clearSearchInput(){
            this.actual_search_phrase = "";
            this.getRecords();
        },
        rowClassGenerator(row){
            var ret = [];
            for(clas in this.row_class){
                if(this.row_class.hasOwnProperty(clas)){
                    if(this.row_class[clas](row)){
                        ret.push(clas);
                    }
                }
            }
            return ret;
        },
        getColByName(name){
            for(i=0;i<this.columns.length;i++) {
                if(this.columns[i].name == name) {
                    return i;
                }
            }
            return -1;
        },
        initPicker() {
            if(typeof this.actual_date_range.from!="undefined" && typeof this.actual_date_range.to!="undefined") {
                this.picker_instance = flatpickr(".flatpickr",{
                    mode:"range",
                    defaultDate:[
                        new Date(parseInt(this.actual_date_range.from)*1000),
                        new Date(parseInt(this.actual_date_range.to)*1000)
                    ],
                    locale:this.calendar_locale
                });
            }
            else {
                this.picker_instance = flatpickr(".flatpickr",{
                    mode:"range",
                    locale:this.calendar_locale
                });
            }
            this.picker_instance.config.onChange.push((dates)=>{
                if(dates.length>1) {
                    this.actual_date_range.from =   Math.round(dates[0].getTime()/1000)
                    this.actual_date_range.to   =   Math.round(dates[1].getTime()/1000)
                    this.getRecords()
                }
                else {
                    this.actual_date_range.from =   undefined
                    this.actual_date_range.to   =   undefined
                }
            })

            this.picker_instance.config.onClose.push(()=>{
                this.getRecords()
            })
        },
        getParamsFromUrl() {
            var url = new URL(window.location.href)
            this.actual_status = url.searchParams.get('status')==null?'all':url.searchParams.get('status')
            this.actual_sort_column = url.searchParams.get('sort')==null?undefined:url.searchParams.get('sort');
            this.actual_search_phrase = url.searchParams.get('search')==null?undefined:url.searchParams.get('search');
            this.actual_sortdir = url.searchParams.get('sortdir')==null?undefined:url.searchParams.get('sortdir');
            this.actual_date_range.from = url.searchParams.get('from')==null?undefined:url.searchParams.get('from');
            this.actual_date_range.to = url.searchParams.get('to')==null?undefined:url.searchParams.get('to');
            if(url.searchParams.get('offset')!=null && url.searchParams.get('limit')!=null){
                var offset = parseInt(url.searchParams.get('offset'));
                var limit = parseInt(url.searchParams.get('limit'));
                var size = limit-offset;
                this.actual_page = Math.round(offset/size)+1;
                this.selectedPagination = String(size);
            }
        },
        status(status_name) {
            this.actual_status = status_name;
            this.actual_page = 1;
            this.getRecords()
        },
        getStatusByNameOrId(name) {
            for(var i=0;i<this.statuses.length;i++) {
                if(this.statuses[i].name==name || this.statuses[i].id==name) {
                    return this.statuses[i]
                }
            }
            return {}
        },
        sort(column_name, sortable) {
            if(typeof sortable !== 'undefined' && !sortable)
                return;
            for(key in this.column_state) {
                this.column_state[key] = undefined
            }
            if(column_name == this.actual_sort_column) {
                switch(this.actual_sortdir) {
                    case "asc":
                        this.actual_sortdir = "desc"
                        this.column_state[column_name] = "desc"
                    break
                    
                    case "desc":
                        this.actual_sortdir = "asc"
                       
                        this.column_state[column_name] = "asc"
                    break;
                    
                    case undefined:
                        this.actual_sortdir = "asc"
                        this.column_state[column_name] = "asc"
                    break;
                }
            }
            else {
                this.actual_sort_column = column_name
                this.actual_sortdir = "asc"
                this.column_state[column_name] = "asc"
            }
            this.getRecords()
        },
        paginate(page) {
            if(page=='...') return;
            this.actual_page = parseInt(page);
            this.getRecords();
        },
        manage_pagination() {
            if(this.actual_page<=0) this.actual_page = 1
        },
        async getRecords(init) {
            try {
                query_params = {}
                if(typeof(this.actual_sortdir)!="undefined") {
                    query_params['sortdir'] = this.actual_sortdir
                }
    
                if(typeof(this.actual_search_phrase)!="undefined" && this.actual_search_phrase !== "") {
                    query_params['search'] = this.actual_search_phrase
                }
    
                if(typeof(this.actual_sort_column)!="undefined") {
                    query_params['sort'] = this.actual_sort_column
                }
    
                if( (typeof(this.actual_status)!="undefined")&&(this.actual_status!='all')) {
                    query_params['status'] = this.actual_status
                   
                }
    
                if(typeof(this.actual_date_range.from)!="undefined") {
                    query_params['from'] = this.actual_date_range.from
                }
    
                if(typeof(this.actual_date_range.to)!="undefined") {
                    query_params['to'] = this.actual_date_range.to
                }
    
                if(typeof(this.actual_page)!="undefined") {
                    query_params['offset'] = (this.actual_page-1)*this.actual_records_per_page
                    query_params['limit'] = (this.actual_page)*(this.actual_records_per_page)
                }
    
                query_string = [];
                for(key in query_params) {
                    query_string.push(encodeURIComponent(key) + "="+encodeURIComponent(query_params[key]))    
                }
                query_string = query_string.join('&')
                this.loader_active = true;
                var response = await axios.get(this.url+query_string,{headers:{'X-Requested-With': 'XMLHttpRequest'}})
                this.loader_active = false;
                this.calendar_locale = response.headers['content-language'].substring(0,2)
                if(this.cal_range) {
                    this.initPicker()
                }
                var parsed_data = undefined;
                if(typeof response.data === 'string'){
                    parsed_data = JSON.parse(response.data);
                } else {
                    parsed_data = response.data;
                }
                this.actual_sum_records = parseInt(parsed_data.count);
                
                //pagination logic
                this.manage_pagination()
    
                parsed_data = parsed_data.data;
                this.full_rows_data = JSON.parse(JSON.stringify(parsed_data));
                
                //order data based on names
                for(var i=0;i<parsed_data.length;i++) {
                   temp_object = {}
                   for(var j=0;j<this.columns.length;j++) {
                       temp_object[this.columns[j].name] = parsed_data[i][this.columns[j].name]
                   }
                   parsed_data[i] = temp_object
                }
                this.rows_data = parsed_data;
                if(!!(window.history&&history.pushState&&(this.url_params))) {
                    if(typeof init == 'undefined' || init == false) {
                        history.pushState(null,null,'?'+query_string)
                    }
                }
    
                var current_ids = this.rows_data.map((r)=>r.id);
                var new_selected_rows = [];
                for(i in this.selected_rows){
                    if(current_ids.indexOf(this.selected_rows[i])!==-1){
                        new_selected_rows.push(this.selected_rows[i]);
                    }
                }
                this.selected_rows = new_selected_rows;
            } catch(error) {
                this.loader_active = false;
            }
        },
        guardActualPage(){
            if(this.actual_page>this.pages_cnt){
                this.actual_page = this.pages_cnt;
                this.getRecords();
            }
        }
    },
    data() {
        return {
            actual_sortdir:"asc",
            actual_search_phrase:undefined,
            actual_sort_column:undefined, //numeric order
            actual_sum_records:0,
            actual_page:1,
            actual_records_per_page:this.pagination_size,
            selectedPagination:this.pagination_size,
            actual_status:'all',
            actual_date_range:{
                "from":undefined,
                "to":undefined
            },
            column_state:{},
            rows_data:[],
            full_rows_data:[],
            picker_instance: undefined,
            placeholder:{
                search:gettext('Search'),
                date_range:gettext('Date Range'),
                all:gettext('all'),
                "ready_to_send":gettext("ready to send"),
                "shipment_sent":gettext("shipment sent"),
                "created":gettext("created"),
                "connected_with_shipment":gettext("reserved"),
                "unit_sent":gettext("unit sent"),
                "active":gettext("active"),
                "archived":gettext("archived")
            },
            calendar_locale:'en',
            selected_rows:[],
            loader_active:false
        }
    },
    watch:{
        actual_search_phrase:function(){
            this.getRecords();
        },
        url:function(){
            this.getRecords();
        },
        selected_rows:function(){
            this.$emit('selected',this.selected_rows,this.rows_data.length);
        },
        selectedPagination:function(){
            this.actual_records_per_page = parseInt(this.selectedPagination);
            this.actual_page = 1;
            this.getRecords();
        },
        actual_page:function(){
            this.guardActualPage();
        },
        pages_cnt:function(){
            this.guardActualPage();
        }
    }
}
</script>