var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.active
      ? _c("div", { staticClass: "alert-box" }, [
          _c(
            "div",
            { staticClass: "alert-box-body" },
            [_vm._t("alert-text"), _vm._v(" "), _c("br"), _c("br")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "alert-box-button" },
            [_vm._t("alert-buttons")],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.active
      ? _c("div", {
          staticClass: "modalbg-dummy",
          on: { click: _vm.backClick },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }