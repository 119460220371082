<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li v-if="item.status!='active'" v-for="item in links" class="breadcrumb-item" :class="item.status"><a :href="item.link">{{ item.name }}</a></li>
            <li v-if="item.status=='active'" style="font-weight:bold;" v-for="item in links" class="breadcrumb-item" :class="item.status">{{ item.name }}</li>
            <li class="added-li">
                <slot name="body"></slot>
            </li>
       </ol>
    </nav>
</template>
<script>
module.exports = {
    name:'customBreadcrumb',
    props:{
        links:{
            type:Array,
            default:()=>[
                {
                    "name":"units",
                    "link":"/units",
                    "status":"active"
                }
            ]
        }
    },
    data() {
        return {
            
        }
    }
}
</script>
<style>
    .added-li {
        margin-left:0.5rem;
    }
</style>