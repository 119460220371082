<template>
    <custom-tooltip :tip="ai.name">
        <span class="badge-container">
            <span class="badge badge-pill badge-pill-left" v-bind:class="badgeClass">
                {{ ai.ai }}
            </span><span class="badge badge-pill badge-pill-right" v-bind:class="[badgeClass,{'ai-badge-fixed':ai.fixed}]">
                {{ ai.decimal_places ? `${ai.value.substring(0, ai.value.length-ai.decimal_places)}.${ai.value.substring(ai.value.length-ai.decimal_places)}` : ai.value }}
            </span>
        </span>
    </custom-tooltip>
</template>
<script>
module.exports = {
    name:'aiPill',
    props:['ai'],
    computed:{
        badgeClass(){
            if(this.ai.required)
                return 'badge-primary';
            if(this.ai.additional)
                return 'badge-secondary';
            return 'badge-success';
        }
    }
}
</script>
<style scoped>
.badge-pill-left {
    padding-right: 5px;
    border-radius: .6em 0 0 .6em;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3));
}

.badge-pill-right {
    padding-left: 5px;
    border-radius: 0 .6em .6em 0;
}

.badge-container {
    white-space: nowrap;
}

.ai-badge-fixed {
    background-image: linear-gradient(45deg,rgba(0,0,0,.15) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.15) 50%,rgba(0,0,0,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    color: #ddd !important;
}
</style>