<template>
    <div>
        <div v-if="active" class="alert-box">
            <div class="alert-box-body">
                <slot name="alert-text"></slot>
                <br><br>
            </div>
            <div class="alert-box-button">
                <slot name="alert-buttons"></slot>
            </div>
        </div>
        <div v-if="active" class="modalbg-dummy" v-on:click="backClick"></div>
    </div>
</template>
<script>
module.exports = {
    name:'customAlertComponent',
    props:{
        active:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        backClick: function(){
            this.$emit('outside_click');
        }
    }
}
</script>