var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-tooltip", { attrs: { tip: _vm.ai.name } }, [
    _c("span", { staticClass: "badge-container" }, [
      _c(
        "span",
        {
          staticClass: "badge badge-pill badge-pill-left",
          class: _vm.badgeClass,
        },
        [_vm._v("\n            " + _vm._s(_vm.ai.ai) + "\n        ")]
      ),
      _c(
        "span",
        {
          staticClass: "badge badge-pill badge-pill-right",
          class: [_vm.badgeClass, { "ai-badge-fixed": _vm.ai.fixed }],
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.ai.decimal_places
                  ? `${_vm.ai.value.substring(
                      0,
                      _vm.ai.value.length - _vm.ai.decimal_places
                    )}.${_vm.ai.value.substring(
                      _vm.ai.value.length - _vm.ai.decimal_places
                    )}`
                  : _vm.ai.value
              ) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }