<template>
    <div class="row">
        <div v-for="item in statuses" :key="item.id" class="col-md-3">
            <a :class="buttonStatusClass(item)" v-bind:style="item.id==selected?'font-weight: bold':''" v-on:click="select(item)" class="btn btn-large btn-flat status-bar"  href="javascript:void(0)">{{ item.displayName }}</a>
        </div>
    </div>
</template>
<script>
module.exports = {
    name:'statusBar',
    props:['statuses','selected'],
    methods:{
        buttonStatusClass(item) {
            return item.id==this.selected ? 'btn-'+item.status_class : 'btn-outline-'+item.status_class;
        },
        select(status){
            this.$emit('select',status);
        }
    }
   
}
</script>