var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "d-flex justify-content-center align-items-center",
          class: _vm.loader_types[_vm.loader_type],
        },
        [_c("i", { staticClass: "fas fa-circle-notch fa-spin fa-3x" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }