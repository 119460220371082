var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.statuses, function (item) {
      return _c("div", { key: item.id, staticClass: "col-md-3" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-large btn-flat status-bar",
            class: _vm.buttonStatusClass(item),
            style: item.id == _vm.selected ? "font-weight: bold" : "",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                return _vm.select(item)
              },
            },
          },
          [_vm._v(_vm._s(item.displayName))]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }