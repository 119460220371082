var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
    _c(
      "ol",
      { staticClass: "breadcrumb" },
      [
        _vm._l(_vm.links, function (item) {
          return item.status != "active"
            ? _c("li", { staticClass: "breadcrumb-item", class: item.status }, [
                _c("a", { attrs: { href: item.link } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.links, function (item) {
          return item.status == "active"
            ? _c(
                "li",
                {
                  staticClass: "breadcrumb-item",
                  class: item.status,
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v(_vm._s(item.name))]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _c("li", { staticClass: "added-li" }, [_vm._t("body")], 2),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }