var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { class: ["card client-card", { "client-card-small": _vm.small }] },
      [
        typeof _vm.client.empty !== "undefined" && _vm.client.empty == true
          ? _c("div", { staticClass: "overlay" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card-header" }, [
          _c("i", { staticClass: "far fa-address-card fa-fw" }),
          _vm._v(" " + _vm._s(_vm.header_name) + "\n        "),
        ]),
        _vm._v(" "),
        !_vm.small
          ? _c("div", { staticClass: "card-body row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.name) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.name)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.street) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.street)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.postal) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.postal)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.location) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.location)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.contury) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.contury)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7" }, [
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.gln) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.gln_number)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.email) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.email)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.phone) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.phone)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.placeholder.contact_person) + ": "),
                  ]),
                  _vm._v(_vm._s(_vm.client.contact_person)),
                ]),
              ]),
            ])
          : _c("div", { staticClass: "card-body row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _vm._v("\n                " + _vm._s(_vm.client.name)),
                _c("br"),
                _vm._v("\n                " + _vm._s(_vm.client.street)),
                _c("br"),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.client.postal) +
                    ", " +
                    _vm._s(_vm.client.location) +
                    " " +
                    _vm._s(_vm.client.contury) +
                    "\n            "
                ),
              ]),
            ]),
      ]
    ),
    _vm._v(" "),
    _vm.error.length > 0
      ? _c("p", { staticClass: "invalid-feedback" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }