var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalShown
    ? _c(
        "custom-alert-component",
        _vm._b(
          {
            on: { outside_click: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "alert-text",
                  fn: function () {
                    return [
                      _vm._t("alert-text", null, {
                        close: _vm.close,
                        select: _vm.select,
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "alert-buttons",
                  fn: function () {
                    return [
                      _vm._t("alert-buttons", null, {
                        close: _vm.close,
                        select: _vm.select,
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "custom-alert-component",
          _vm.mergedModalSettings,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }