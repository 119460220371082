<template>
    <div class="md-form">
        <div class="input-group">
            <div class="prepend-container" v-if="prepend">
                <slot name="prepend-body"></slot>
            </div>
            <input v-if="date" ref="date" v-on:blur="flatpickrInstance.setDate(inputData, true)" :name="name" v-bind:disabled="disabled" v-model="inputData" :placeholder="placeholder" v-bind:id="'flatpickr_'+this._uid" :class="['form-control',validClass]" type="text" :pattern="selected_regex" :required="required">
            <input v-if="!date" ref="non-date" v-on:keydown.enter="enterPressed" v-on:blur="$emit('focusoff',name)" :name="name" v-bind:disabled="disabled" v-on:input="inputChange($event)" v-model="inputData" :placeholder="placeholder" v-bind:id="'id_cleave'+this._uid" :class="['form-control class_cleave'+this._uid,validClass]" :type="type" :pattern="selected_regex" :required="required" :maxlength="binding_max_length" v-mask="decimal_places_mask"/>
        </div>
        <label v-if="label!=''" :for="'id_cleave'+this._uid">{{ label }}</label>
        <div v-if="valid_text != ''" class="valid-feedback">
            {{ valid_text }}
        </div>
        <div v-if="invalid_text != ''" class="invalid-feedback">
            {{ invalid_text }}
        </div>
        <div v-if="warning_text != '' && validClass == 'warning'" class="warning-feedback">
            {{ warning_text }}
        </div>
    </div>
</template>
<script>
module.exports = {
    name:'customInputField',
    props:{
        options:{
            type:Object,
            default:()=>{}
        },
        name:{
            type:String,
            default:''
        },
        label:{
            type:String,
            default:''
        },
        placeholder:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:'text'
        },
        disabled:{
            type:Boolean,
            default:false
        },
        valid_text:{
            type:String,
            default:''
        },
        invalid_text:{
            type:String,
            default:''
        },
        warning_text:{
            type:String,
            default:''
        },
        value:{
            type:String,
            default:''
        },
        regex:{
            type:[String,RegExp],
            default:''
        },
        control_digit:{
            type:Boolean,
            default:false
        },
        required:{
            type:Boolean,
            default:false
        },
        date:{
            type:Boolean,
            default:false
        },
        date_locale:{
            type:String,
            default:''
        },
        date_format:{
            type:String,
            default:'ymd'
        },
        max_date:{
            type:String,
            default: null
        },
        prepend:{
            type:Boolean,
            default:false
        },
        max_length:{
            type:Number,
            default:undefined
        },
        decimal_places:{
            type:Number,
            default:null
        }
    },
    mounted() {
        if(this.date){
            this.flatpickrInstance = flatpickr("#flatpickr_"+this._uid,{
                allowInput: true,
                dateFormat: this.date_format,
                locale: this.date_locale.split('-')[0],
                maxDate: this.max_date,
                onChange: (e,selected,i)=>{
                    this.inputData = selected;
                    this.validStatus = this.validate();
                    this.$emit('input', this.inputData);
                    this.$refs['date'].blur();
                }
            });
        }
    },
    computed:{
        decimal_places_mask(){
            const max_length = parseInt(this.max_length)
            const decimal_places = parseInt(this.decimal_places)
            if(!isNaN(max_length) && !isNaN(decimal_places) && decimal_places > 0 && max_length > decimal_places){
                return `9{${max_length-decimal_places}}.9{${decimal_places}}`
            }
            return undefined
        },

        binding_max_length(){
            if(typeof this.decimal_places_mask !== 'undefined'){
                return this.max_length+1
            }
            return this.max_length
        },

        selected_regex:function(){
            if(this.regex!=""){
                if(typeof this.decimal_places_mask !== 'undefined'){
                    return `${this.regex}|^(${this.decimal_places_mask.replace(/9/g,'\\d')})$`;
                }
                return this.regex;
            }
            else if(typeof this.validationRegex[this.type] != "undefined"){
                return this.validationRegex[this.type];
            }
            else{
                return undefined;
            }
        }
    },
    methods:{
        enterPressed(e){
            this.$emit('inputenter',e);
        },
        inputChange(event) {
            this.validStatus = this.validate()
            if(this.validStatus === true){
                if(this.control_digit) {
                    if(this.checkControlSum(this.inputData) == true) {
                        this.validClass = "valid";
                    } else {
                        this.validClass = "warning";
                    }
                }
            }
            this.$emit('change',this.inputData,this.name,this.validStatus)
            this.$emit('input', this.inputData);
        },

        checkControlSum(full_number) {
            var number = full_number.substring(0,full_number.length-1)
            var sum = 0;
            var factor = 3;
            for(var i=0;i<number.length;i++) {
                sum += parseInt(number[number.length-i-1])*factor;
                factor = 4 - factor;
            }
            nearDec = (Math.ceil(sum/10))*10;
            var controlDigit = nearDec - sum;
            return controlDigit == parseInt(full_number[full_number.length-1]);
        },

        validate() {
            if(this.inputData.length==0) {
                if(!this.required){
                    this.validClass="empty";
                    return "empty";
                }
                if(this.required) {
                    this.validClass="invalid"
                    return false
                }
            }
            
            if(typeof this.selected_regex=="undefined"){
                this.validClass="empty";
                return true;
            }

            var match_result = this.inputData.match(this.selected_regex);
            if(match_result==null) {
                this.validClass="invalid"
                return false
            } else {
                this.validClass="valid"
                return true
            }
        },
        giveFocus(){
            if(this.date){
                this.$refs['date'].focus();
            }
            else{
                this.$refs['non-date'].focus();
            }
        }
    },
    data() {
        return {
            flatpickrInstance: undefined,
            validationRegex:{
                ip:/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm,
                email:/.*?@.*?\.[a-zA-Z0-9]+/gm
            },
            inputData:'',
            validClass:"empty",
            validStatus:false
        }
    },
    watch:{
        value:{
            immediate: true,
            handler() {
                this.inputData = this.value;
                this.inputChange()
            }
        }
    }
}
</script>
<style>
.prepend-container {
    margin-top:0.5rem;
}
</style>