<template>
    <span :id="'tool_'+_uid" class="tool" :data-tip="tip" @mouseover="hover">
        <slot></slot>
    </span>
</template>
<script>
module.exports = {
    name:'customTooltip',
    props:['tip'],
    mounted(){
        this.addRule = (function (style) {
            var sheet = document.head.appendChild(style).sheet;
            return function (selector, css) {
                var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                    return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
                }).join(";");
                sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
            };
        })(document.createElement("style"));
        this.hover();
    },
    methods:{
        hover(){
            this.middle = (this.$el.getBoundingClientRect().left+this.$el.getBoundingClientRect().right)/2;
            this.top = this.$el.getBoundingClientRect().top;
            this.windowH = window.innerHeight;
        },
        updatePos(){
            this.addRule('#tool_'+this._uid+':before', {
                top:    (this.top-13)+"px",
                left:   (this.middle-3)+"px"
            });

            this.addRule('#tool_'+this._uid+':after', {
                bottom: (this.windowH-this.top+12)+"px",
                left:   (this.middle-1.5)+"px"
            });
        }
    },
    watch:{
        middle: function(){
            this.updatePos();
        },
        top: function(){
            this.updatePos();
        },
        windowH: function(){
            this.updatePos();
        }
    },
    data(){
        return {
            addRule:    undefined,
            middle:     0,
            top:        0,
            windowH:    0
        }
    }
}
</script>