<template>
    <div>
        <div :class="['card client-card',{'client-card-small':small}]">
            <div class="overlay" v-if="typeof client.empty !== 'undefined' && client.empty == true"></div>
            <div class="card-header">
                <i class="far fa-address-card fa-fw"></i> {{ header_name }}
            </div>
            <div class="card-body row" v-if="!small">
                <div class="col-md-5">
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.name }}: </span>{{ client.name }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.street }}: </span>{{ client.street }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.postal }}: </span>{{ client.postal }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.location }}: </span>{{ client.location }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.contury }}: </span>{{ client.contury }}</p>
                </div>
                <div class="col-md-7">
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.gln }}: </span>{{ client.gln_number }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.email }}: </span>{{ client.email }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.phone }}: </span>{{ client.phone }}</p>
                    <p class="card-text"><span class="font-weight-light">{{ placeholder.contact_person }}: </span>{{ client.contact_person }}</p>
                </div>
            </div>
            <div class="card-body row" v-else>
                <div class="col-md-12">
                    {{ client.name }}<br>
                    {{ client.street }}<br>
                    {{ client.postal }}, {{ client.location }} {{ client.contury }}
                </div>
            </div>
        </div>
        <p v-if="error.length>0" class="invalid-feedback">{{ error }}</p>
    </div>
</template>
<script>
module.exports = {
    name:'customClientPlaceholder',
    props:{
        client:{
            type:Object,
            default:function() {
                return {
                    name:"-",
                    street:"-",
                    postal:"-",
                    location:"-",
                    contury:"-",
                    email:"-",
                    phone:"-",
                    gln_number:"-",
                    contact_person:"-"
                }
            }
        },
        header_name:{
            type:String,
            default:""
        },
        placeholder:{
            type:Object,
            default:function() { 
                return{
                    "name":gettext("Name"),
                    "street":gettext("Street"),
                    "postal":gettext("Postal code"),
                    "location":gettext("Location"),
                    "contury":gettext("Contury"),
                    "gln":gettext("GLN number"),
                    "email":gettext("Email address"),
                    "phone":gettext("Phone number"),
                    "contact_person":gettext("Contact person")
                }
            }
        },
        error:{
            type:String,
            default:""
        },
        small:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style scoped>
.client-card {
    border: 2px solid #33b5e5
}

.client-card .card-header {
    background: rgba(51,181,229,0.25);
    color: rgb(0, 125, 171);
}

.card.client-card .card-body .card-text {
    font-size: 1rem;
}

.client-card-small {
    margin-top: 0px;
}

.card.client-card.client-card-small .card-body .card-text {
    font-size: 0.85rem;
}

.client-card-small .card-body.row {
    padding: .75rem 1.25rem;
}

.client-card-small .card-body .col-md-12 {
    padding: 0;
}

.client-card .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    background-image: linear-gradient(45deg,rgba(0,0,0,.15) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.15) 50%,rgba(0,0,0,.15) 75%,transparent 75%,transparent);
    background-size: 1.5rem 1.5rem;
    z-index: 1000;
    border-radius: 2px;
}
</style>