var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.modalactive
    ? _c(
        "div",
        {
          staticClass: "modal fade show",
          style: { display: "block", "z-index": 2050 },
          attrs: { tabindex: "-1", role: "dialog" },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-notify",
              class: _vm.modal_type,
              staticStyle: { "max-width": "850px" },
              attrs: { role: "document" },
            },
            [
              _c(
                "div",
                { staticClass: "modal-content" },
                [
                  _vm._t("header"),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer justify-content-center" },
                    [_vm._t("footer")],
                    2
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modalbg", on: { click: _vm.backClick } }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }