<template>
    <div v-if="modalactive" class="modal fade show" v-bind:style="{'display':'block','z-index':2050}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-notify" v-bind:class="modal_type" role="document" style="max-width:850px;">
            <div class="modal-content">
                <slot name="header"></slot>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer justify-content-center">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
        <div class="modalbg" v-on:click="backClick"></div>
    </div>
</template>
<script>
module.exports = {
    name:'customModalComponent',
    props:{
        url:{
            type:String,
            default:''
        },
        modal_type:{
            type:String,
            default:''
        },
        modalactive:{
            type:Boolean,
            default:false
        },
        backurl:{
            type:String,
            default:''
        },
        init_flag:{
            type:Boolean,
            default:true
        },
        url_params:{
            type:Boolean,
            default:true
        }
    },
    methods:{
        backClick: function(){
            this.$emit('outside_click');
        },
        escapeCloser: function(evt){
            if(("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) || evt.keyCode === 27){
                //document.removeEventListener("keydown",escapeCloser);
                this.$emit('outside_click');
            }
        }
    },
    watch:{
        modalactive:function() {
            if(this.url_params) {
                if(this.modalactive) {
                    if(!!(window.history&&history.pushState)) {
                        history.pushState(null,null,this.url)   
                    }
                }
                else {
                    if(this.init_flag) {
                        this.$emit('init_flag',false)
                        if(!!(window.history&&history.pushState)) {
                            history.pushState(null,null,this.backurl)
                        }
                    } else {
                        if(!!(window.history&&history.pushState)) {
                            history.back()    
                        }
                    }
                }
            }
            if(this.modalactive){
                document.addEventListener("keydown",this.escapeCloser,true);
            }
            else{
                document.removeEventListener("keydown",this.escapeCloser,true);
            }
        }
    }
}
</script>