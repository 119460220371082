<template>
    <div v-if="show" class="d-flex justify-content-center align-items-center" v-bind:class="loader_types[loader_type]">
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
    </div>
</template>
<script>
module.exports = {
    name:'customLoader',
    props:{
        show:{
            type:Boolean,
            default:false

        },
        loader_type:{
            type:String,
            default:"default"
        }
    },
    data() {
        return {
            loader_types:{
                default:"loader-container",
                table:"loader-container-table"
            }
        }
    }
}
</script>
<style>
.loader-container {
    z-index:1000;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:white;
    opacity:0.9;
    color:#404040;   
}

.loader-container-table {
    z-index:1000;
    position:absolute;
    left:0;
    top:110px;
    width:100%;
    height:calc(100% - 110px);
    background:white;
    opacity:0.9;
    color:#404040;   
}
</style>