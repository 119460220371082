var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("custom-loader", { attrs: { show: _vm.loader } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "custom-card-header",
          on: {
            click: function ($event) {
              return _vm.$emit("header_click")
            },
          },
        },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active,
                expression: "active",
              },
            ],
            staticClass: "custom-card-body",
          },
          [_vm._t("body")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }